.feedback-container {
    width: 100%; /* Use a largura total disponível */
    max-width: 330px; /* Largura máxima para não esticar demais */
    background-color: #FDFDFD;
    margin: 50px 0; /* Margem vertical para separação entre itens */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra para destacar */
    border-radius: 16px; /* Bordas levemente arredondadas */
    margin-left: 8%;
}



.feedback-title {
    text-align: start;
    margin-left: 15px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #54411F;
}

.feedback-comment {
    text-align: start;
    margin-left: 15px;
    margin-bottom: 20px;
    font-size: 13px;
    color: #54411F;
}
.star {
    color: gold;
    font-size: 20px;
    margin: 0 3px;
    margin-bottom: -10px;
}

.feedback-rating {
    display: flex;
    justify-content: center;
}

.slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-track {
    display: flex;
    align-items: center;
}

.button-container {
    display: flex;
    justify-content: center; /* Centraliza o botão horizontalmente */
    margin-top: 20px;
}

.submit-button, .back-button {
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Submit Button Styles */
.submit-button {
    background-color: #4CAF50; /* Verde suave */
    color: white;
}

.submit-button:hover {
    background-color: #45a049; /* Verde um pouco mais escuro no hover */
    transform: translateY(-2px); /* Elevação no hover */
}

.submit-button:active {
    background-color: #388e3c; /* Verde ainda mais escuro ao clicar */
    transform: translateY(0); /* Remove elevação ao clicar */
}

/* Back Button Styles */
.back-button {
    background-color: #808080; /* Vermelho suave */
    color: white;
}

.back-button:hover {
    background-color: #e53935; /* Vermelho um pouco mais escuro no hover */
    transform: translateY(-2px); /* Elevação no hover */
}

.back-button:active {
    background-color: #d32f2f; /* Vermelho ainda mais escuro ao clicar */
    transform: translateY(0); /* Remove elevação ao clicar */
}

.feedback-form-container {
    background-color: #f6f0e4;
    padding: 40px;
    border-radius: 16px;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Arial', sans-serif;
    color: #4a391f;
}

.form-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.form-description {
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 1.5;
}

.feedback-question {
    margin-bottom: 30px;
    text-align: left;
}

.feedback-question p {
    font-size: 16px;
    margin-bottom: 10px;
}

.rating-scale {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slider {
    width: 100%;
    margin: 0 10px;
    accent-color: #c2b09e;
}

.feedback-input {
    width: 100%;
    padding: 10px;
    border: 2px solid #c2b09e;
    border-radius: 8px;
    margin-top: 10px;
    font-size: 16px;
}

.form-thankyou {
    margin-top: 30px;
    font-size: 14px;
    color: #4a391f;
}

.custom-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #e53935;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 10;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.custom-modal-close-button:hover {
    background-color: #d32f2f;
}

.custom-modal-close-button:active {
    background-color: #b71c1c;
}


@media (min-width: 768px) {
    .feedback-container {
        width: 100%; /* Use a largura total disponível */
        max-width: 600px; /* Largura máxima para não esticar demais */
        background-color: #FDFDFD;
        margin-left: 27%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra para destacar */
        border-radius: 16px; /* Bordas levemente arredondadas */
    }

    .feedback-title {
        text-align: center;
        margin-left: 25px;
        margin-right: 20px;
        margin-bottom: 20px;
        font-size: 15px;
        color: #54411F;

    }

    .feedback-comment {
        text-align: center;
        margin-right: 20px;

        margin-left: 25px;
        margin-bottom: 20px;
        font-size: 13px;
        color: #54411F;
    }


    .feedback-form-container {
        background-color: #f6f0e4;
        padding: 10px;
        border-radius: 16px;
        max-width: 120%;
        margin: 0 auto;
        text-align: center;
        font-family: 'Arial', sans-serif;
        color: #4a391f;
    }

}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .feedback-container {
        width: 100%; /* Use a largura total disponível */
        background-color: #FDFDFD;
        margin: 50px 0; /* Margem vertical para separação entre itens */
        margin-left: 15%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra para destacar */
        border-radius: 16px; /* Bordas levemente arredondadas */
    }


}
