@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');


body, html {
    overflow-x: hidden;
}



.topHeader {
    text-align: start;
    margin-top:10px;
    font-size: x-large;
    color: white;
    opacity: 0.7;
    font-family: Inter;
    font-weight: 700;
}
.App {
    font-family: sans-serif;
    text-align: center;
}
.titleOptions{
    color: white;
    display: flex;
    justify-content: center; /* Centraliza os itens */
    align-items: center; /* Centraliza os itens */
    width: 100%; /* Garante que os itens sejam distribuídos no container */
    margin-top: 20px;
}
/* Adicione essa classe para evitar quebra de linha */
.no-wrap {
    white-space: nowrap;
}

.containerStyle{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.buttonTitleAboutUs {
    background-color: #D9D9D9;
    color: black;
    border-radius: 19px;
    padding: 5px 5px; /* Aumentei um pouco o padding horizontal para melhor aparência */
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 8px; /* Ajuste conforme necessário para o tamanho desejado */
    margin-left: 5px;
}

.buttonTitleOurServices {
    background-color: #D9D9D9;
    color: black;
    border-radius: 19px;
    padding: 5px 5px; /* Aumentei um pouco o padding horizontal para melhor aparência */
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 8px; /* Ajuste conforme necessário para o tamanho desejado */
    margin-left: 5px;
}

.buttonTitleContact {
    background-color: #D9D9D9;
    color: black;
    border-radius: 19px;
    padding: 5px 5px; /* Aumentei um pouco o padding horizontal para melhor aparência */
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 8px; /* Ajuste conforme necessário para o tamanho desejado */
    margin-left: 10px;
    margin-right: 10px;
}


.imageStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%) contrast(125%) sepia(20%) hue-rotate(220deg);
}

.title {
    color: white;
margin-bottom: 20px;
    margin-left:20px;
}
.ExperienceImage{
    margin-top: 30px;
    height: 162px;
    background-size: cover;
}

.CabecalhoImage{
    height: 250px;
    width: 150%;
    background-size: cover;
    margin-bottom: 10px; /* Adicione esta linha para criar espaço entre a imagem e o texto */

}

.ExperienceTextDate {
    font-weight: 500;
    font-size: 40px;
    line-height: 29px;
    color: black;
}

.ExperienceTextDescription{
    justifyItems: center;
    margin-left: 15px;
    margin-top:10px;
    text-align: justify;
}

.ExperienceContactButton{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 100px;
    margin-left: 15px;
    background-color: #FFD563;
}



.OurServicesTitleText{
    margin-top: 100px;
    font-weight: 500;
    color: black;
    font-family: 'Nunito', sans-serif;

}

.OurServicesImages {
    margin-top: 50px;
    width: 81px;
    height: 87px;
    background-size: cover;
    border: 2px solid #956737
}
.ourServicesSpaceCards{
    display: flex;
    justify-content: space-evenly;

}
.OurServicesBottom {
    margin-bottom: 110px;
}
.FeedBackBottom {
    margin-bottom: 40px;
}

.OurServicesText{
    width: 70%;
    text-align: center;
    margin-top: 10px;
    padding: 0 5px;

}

.titleText {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: white;
}

.titleSubText {
    margin-left: -10px;
    margin-right: -20px;
    text-align: center;
    color: black;
    font-size: 14px;
    font-weight: normal;
    font-family: 'Nunito', sans-serif;

}

.contactInfo span {
    font-family: 'Inter', sans-serif;
    font-size: 7px;
    color: black;
    margin-top: 5px; /* Espaço entre as linhas de texto */
}

.smokeSideR {
    height: 400px;
    width: 100%;
    margin-top: -20px;
    margin-right: -75px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; /* Evita repetição se a imagem for menor que o contêiner */
    opacity: 80%;


}
.smokeSideL {
    height: 350px;
    width: 100%;
    margin-left: -100px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; /* Evita repetição se a imagem for menor que o contêiner */
    opacity: 80%;

}
.container {
    display: flex;
}

.aboutUsCard {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, .50%);
    background-attachment: fixed; /* Adicione esta linha para o efeito parallax */

}
.ourServicesCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.titleTextAboutUs {
    margin-top: 125px;
font-weight: bold;
    font-family: 'Nunito', sans-serif;

}
.titleSubTextAboutUs {
    margin-top: 30px;
    width: 75%; /* Adiciona largura máxima para forçar quebra de linha */
    text-align: center;
margin-bottom: 120px;
    font-family: 'Nunito', sans-serif;


}

.WhyUsCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #E8E7E7 0%, #FFFFFF 52.5%, #E8E7E7 100%)
}

.WhyUs1TitleText{
    margin-top: 50px;
    font-weight: bolder;
    font-size: 18px;
    color: black;
    font-family: 'Nunito', sans-serif;

}
.WhyUs2TitleText{
    margin-top: -10px;
    font-weight: 500;
    font-size: 25px;
    color: black;
    font-family: 'Kaushan Script', sans-serif;

}
.WhyUs3TitleText{
    font-weight: bolder;
    margin-top: -5px;
    color: black;
    font-family: 'Nunito', sans-serif;

}
.WhyUsImages {
    margin-top: 50px;
    width: 130px;
    height: 225px;
    background-size: cover;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
}
.WhyUsImages2 {
    margin-top: 65px;
    width: 130px;
    height: 225px;
    background-size: cover;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
}

.WhyUsSpaceCards{
    display: flex;
    justify-content: space-evenly;
    gap: 15px;
    margin-top: 10px;
}

.WhyUsText{
    text-align: center;
    margin-top: 10px;
    padding: 0 5px;
    font-size: 10px;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
}

.contactTitleText {
    font-size: 15px;
    color: white;
    text-align: center;
    height: 30px;
    margin: 0 100px; /* Substitui margin-left e margin-right */
    font-weight: bold;
    background-color: #375195;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito', sans-serif;

}

.contactInfoText {
    font-size: 10px;
    margin-left: 35px;
    margin-right: 75px;

    margin-top: 50px;
    font-family: 'Nunito', sans-serif;

}

.contactDetail {
    margin-top: 5px;
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.contactSocialMedia {
    margin-top: 5px;
    margin-left: 20px;
padding-bottom: 70px;
    display: flex;
    align-items: center;
}

.contactLabel {
    font-size: 10px;
    margin-left: 15px;
}

.contactValue {
    font-size: 10px;
    margin-left: 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.copyButton {
    margin-left: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.copyStatus {
    margin-left: 5px;
    font-size: 10px;
    color: green;
}

.contactLink {
    font-size: 10px;
    margin-left: 15px;
    margin-top: 5px;
}

.feedBackCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    background-position: center;
    background-size: cover;
    background-color: rgba(0, 0, 0, .10%);
    padding: 20px; /* Adiciona padding para garantir espaço interno */
    box-sizing: border-box; /* Inclui padding e border no cálculo de largura/altura */
    min-width: 320px; /* Largura mínima para evitar compressão */
}


.titleTextFeedBack {

    margin-top: 70px;
    font-weight: bold;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    color: black;
    text-align: center;

}

.leavefeedbackbutton {
    font-size: 15px;
    color: black;
    text-align: center;
    height: 30px;
    margin-top:50px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito', sans-serif;
    border-radius: 10px;
    margin-bottom: 40px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@media (min-width: 768px) {
    .row {
        display: flex;
        justify-content: center; /* Centraliza o conteúdo */
    }

    .titleOptions {
        flex-direction: row; /* Alinha os itens horizontalmente */
        justify-content: center; /* Centraliza os itens */
        width: 100%; /* Garante que os itens sejam distribuídos no container */
        max-width: 1200px; /* Limita a largura máxima para evitar expansão excessiva */
        margin: 0 auto; /* Centraliza o container em telas maiores */
        margin-top: 20px;
    }

    .buttonTitleAboutUs,
    .buttonTitleOurServices,
    .buttonTitleContact {
        margin: 0 15px; /* Espaçamento entre os botões */
        font-size: 14px; /* Aumenta o tamanho da fonte para desktop */
        width: 100px;
        text-align: center;
    }

    .contactInfo {
        display: flex;
        flex-direction: column;
        align-items: center; /* Centraliza as informações de contato */
        margin-left: 15px;
    }

    .contactInfo span {
        font-size: 12px; /* Aumenta o tamanho da fonte para os spans */
        margin-top: 5px; /* Espaço entre as linhas de texto */
    }

    .smokeSideL {
        height: 400px;
        width: 30%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat; /* Evita repetição se a imagem for menor que o contêiner */
        opacity: 80%;
    }

    .smokeSideR {
        height: 400px;
        width: 30%;
        margin-left: auto;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat; /* Evita repetição se a imagem for menor que o contêiner */
        opacity: 80%;

    }

    .CabecalhoImage {
        height: 400px;
        width: 60%;

        background-size: cover;
        background-position: center;
        margin-bottom: -20px;

    }

    .titleSubText {
        text-align: center;
        color: black;
        margin: 20px 0 -40px 30px;
        font-size: 20px;
        font-weight: normal;
        font-family: 'Nunito', sans-serif;
        width: 75%; /* Adiciona largura máxima para forçar quebra de linha */
    }

    .aboutUsCard {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed; /* Adicione esta linha para o efeito parallax */
        background-size: cover;

    }

    .titleTextAboutUs {
        margin-top: 145px;
        font-weight: bold;
        font-size: 25px;
    }

    .titleSubTextAboutUs {
        margin-top: 70px;
        width: 65%; /* Adiciona largura máxima para forçar quebra de linha */
        text-align: center;
        margin-bottom: 170px;
        font-size: 25px;
    }

    .ourServicesCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
    }


    .OurServicesImages {
        margin-top: 50px;
        width: 210px;
        height: 178px;
        background-size: cover;
        border: 2px solid #956737
    }
    .OurServicesBottom {
        margin-bottom: 110px;
    }

    .OurServicesText{
        width: 70%;
        text-align: center;
        margin-top: 10px;
        padding: 0 5px;
        font-family: 'Nunito', sans-serif;

    }

    .ourServicesSpaceCards{
       display: flex;
        justify-content: space-evenly;
        gap: 150px;
        margin-top: 30px;
    }


    .WhyUsCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #E8E7E7 0%, #FFFFFF 52.5%, #E8E7E7 100%)
    }

    .WhyUs1TitleText{
        margin-top: 50px;
        font-weight: bolder;
        font-size: 28px;
        color: black;
        font-family: 'Nunito', sans-serif;

    }
    .WhyUs2TitleText{
        margin-top: -10px;
        font-weight: 500;
        font-size: 35px;
        color: black;
        font-family: 'Kaushan Script', sans-serif;

    }
    .WhyUs3TitleText{
        font-weight: bolder;
        margin-top: -5px;
        font-size: 20px;
        color: black;
        font-family: 'Nunito', sans-serif;

    }
    .WhyUsImages {
        margin-top: 50px;
        width: 280px;
        height: 385px;
        background-size: cover;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 5px;
    }
    .WhyUsImages2 {
        margin-top: 65px;
        width: 280px;
        height: 385px;
        background-size: cover;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 5px;
    }

    .WhyUsSpaceCards{
        display: flex;
        justify-content: space-evenly;
        gap: 100px;
        margin-top: 20px;
    }

    .WhyUsText{
        text-align: center;
        margin-top: 10px;
        padding: 0 5px;
        font-size: 15px;
        font-weight: bold;
        font-family: 'Nunito', sans-serif;
    }


    .contactTitleText {
        font-size: 20px;
        color: white;
        text-align: center;
        height: 50px;
        margin: 0 300px; /* Substitui margin-left e margin-right */
        font-weight: bold;
        background-color: #375195;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Nunito', sans-serif;

    }

    .contactInfoText {
        font-size: 15px;
        margin-left: 300px;
        margin-right: 75px;

        margin-top: 50px;
        font-family: 'Nunito', sans-serif;

    }

    .contactDetail {
        margin-top: 5px;
        margin-left: 285px;
        display: flex;
        align-items: center;
    }

    .contactSocialMedia {
        margin-top: 5px;
        margin-left: 285px;
        padding-bottom: 70px;
        display: flex;
        align-items: center;
    }

    .contactLabel {
        font-size: 10px;
        margin-left: 15px;
    }

    .contactValue {
        font-size: 10px;
        margin-left: 5px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .copyButton {
        margin-left: 5px;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    .copyStatus {
        margin-left: 5px;
        font-size: 10px;
        color: green;
    }

    .contactLink {
        font-size: 10px;
        margin-left: 15px;
        margin-top: 5px;
    }

    .feedBackCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        background-position: center;
        background-size: cover;
        background-color: rgba(0, 0, 0, .10%);
        padding: 20px; /* Adiciona padding para garantir espaço interno */
        box-sizing: border-box; /* Inclui padding e border no cálculo de largura/altura */
        min-width: 320px; /* Largura mínima para evitar compressão */
    }

    .leavefeedbackbutton {
        font-size: 15px;
        color: black;
        text-align: center;
        height: 30px;
        margin-top:100px;
        margin-left: 500px;
        margin-right: 500px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Nunito', sans-serif;
        border-radius: 10px;
        margin-bottom: 40px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .titleTextFeedBack {
        margin-top: 120px;
        font-weight: bold;
        font-size: 20px;
        font-family: 'Nunito', sans-serif;
        color: black;
        text-align: center;

    }

    .FeedBackBottom {
        margin-bottom: 110px;
    }

}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .titleOptions{
        color: white;
        display: flex;
        justify-content: center; /* Centraliza os itens */
        align-items: center; /* Centraliza os itens */
        width: 80%; /* Garante que os itens sejam distribuídos no container */
        margin-top: 20px;
    }

    .CabecalhoImage{
        height: 180px;
        background-size: cover;
        margin-bottom: 20px; /* Adicione esta linha para criar espaço entre a imagem e o texto */

    }
    .leavefeedbackbutton {
        width: 200px;
        margin-left: 40%;

    }

    .ourServicesSpaceCards{
        display: flex;
        justify-content: space-evenly;
        gap: 100px;
        margin-top: 30px;
    }

    .WhyUsSpaceCards{
        display: flex;
        justify-content: space-evenly;
        gap: 10px;
        margin-top: 20px;
    }
}
