@media only screen and (max-width: 1023px) {

    .App {
        font-family: sans-serif;
        text-align: center;
        color: brown;
    }

    .titleText2 {
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        color: white;
    }
    .topHeader {
        margin-left:30px;
    }

    .titleOptions{
        font-size: 8px;
    }

    .ExperienceImage{
        margin-left: 15px;
        height: 162px;
        width: 700px;

    }

    .ExperienceTextDescription{
        font-size: 10px;
        margin-right: 35px;
    }

    .ExperienceContactButton{
        margin-top: 20px;
    }


    .experienceFirstText{
        font-size: 50px;
        text-align: justify;

    }

    .experienceSecondText{
        font-size: 18px;
        text-align: justify;

    }

    .OurServicesTitleText{
        font-size: 20px;
        margin-top: 30px;
    }
    .OurServicesImages {
        height: 100px;
        width: 100px;
    }

    .OurServicesText{
        font-size:10px;
    }

    .WhyUsImage{
        margin-top: 10px;
        margin-left: 15px;
        height: 100px;
        width: 130px;
        background-size: cover;
    }

    .WhyUsImage{
        height: 100px;
        width: 130px;
    }

    .WhyUsTitleText{
        font-size: 20px;
    }

    .WhyUsGroupCheck{
        flex-direction: column;
        margin-top: 30px;
    }

    .WhyUsTextCheck{
        font-size: 10px;
        margin-left: 5px;
    }
}

@media only screen and (min-width: 1024px) {

    .App {
        font-family: Sathu;
        text-align: center;
        color: purple;
    }

    .topHeader {
        margin-left: 200px;
    }
    .titleOptions{
        margin-right: 200px;

        font-size: 15px;
    }


    .title{
        height: 300px;
    }

    .ExperienceImage{
        margin-left: 150px;
        width: 400px;
    }

    .ExperienceTextDescription{
        margin-right: 180px;
        font-size: 15px;
    }

    .ExperienceContactButton{
        margin-top: 35px;
    }

    .titleText{
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
    }

    .titleText2 {
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 29px;
        color: white;
    }
    .OurServicesTitleText{
        font-size: 30px;
        margin-top: 50px;
    }

    .OurServicesImages {
        height: 140px;
        width: 140px;
    }

    .OurServicesText{
        font-size:12px;
    }

    .WhyUsLeftSide{
        margin-left: 30%;
        margin-top: 10px;

    }

    .WhyUsTitleText{
        font-size: 30px;
    }

    .WhyUsImage{
        height: 200px;
        width: 230px;
    }

    .WhyUsGroupCheck{
        margin-top: 100px;
        flex-direction: column;
    }

    .WhyUsTextCheck{
        font-size: 15px;
        margin-left: 5px;
    }

    .AboutUsCollection{
        margin-left: 110px;
    }
}
